.calculation-results {
  margin-top: 2rem;
  padding: 2rem;
  background: #f8fafc;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-sm);
}

.calculation-results h2 {
  margin: 0 0 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-color);
}

.results-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.wall-result {
  padding: 1.5rem;
  background: white;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-sm);
  transition: all 0.2s ease;
}

.wall-result:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow);
}

.wall-result h3 {
  margin: 0 0 1rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-color);
}

.wall-result ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.wall-result li {
  margin: 0.5rem 0;
  padding: 0.75rem;
  background: #f8fafc;
  border-radius: 6px;
  font-size: 0.875rem;
  color: var(--text-light);
}

.overhang {
  color: var(--danger-color) !important;
  background: #fee2e2 !important;
}

.underhang {
  color: var(--success-color) !important;
  background: #ecfdf5 !important;
}

.alignment-info {
  margin-top: 1.5rem;
  padding: 1.5rem;
  background: white;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-sm);
}

.aligned {
  color: var(--success-color);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.not-aligned {
  color: var(--danger-color);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
} 