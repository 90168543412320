.app {
  min-height: 100vh;
  background: linear-gradient(135deg, #f6f8fc 0%, #e9edf5 100%);
}

header {
  text-align: center;
  padding: 3rem 1rem;
  background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
  color: white;
  margin-bottom: 2rem;
  box-shadow: var(--shadow);
}

header h1 {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: -0.025em;
}

header p {
  margin: 1rem 0 0;
  font-size: 1.125rem;
  opacity: 0.9;
}

.calculator-container {
  max-width: 1200px;
  margin: -4rem auto 2rem;
  padding: 2rem;
  background: var(--card-background);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-lg);
}

.calculate-button {
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--primary-hover) 100%);
  color: white;
  font-weight: 600;
  padding: 1rem 2rem;
  border-radius: var(--border-radius);
  width: 100%;
  margin: 2rem 0;
  transform: translateY(0);
  transition: all 0.2s ease;
}

.calculate-button:hover {
  transform: translateY(-1px);
  box-shadow: var(--shadow);
}

.calculate-button:active {
  transform: translateY(0);
}

@media (max-width: 768px) {
  header {
    padding: 2rem 1rem;
  }

  header h1 {
    font-size: 2rem;
  }

  .calculator-container {
    margin: -2rem 1rem 2rem;
    padding: 1.5rem;
  }
} 