.panel-visualizer {
  margin-top: 2rem;
  padding: 2rem;
  background: #f8fafc;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-sm);
}

.visualization-canvas {
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
  background: white;
  box-shadow: var(--shadow-sm);
} 