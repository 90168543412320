.wall-manager {
  background: #f8fafc;
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-sm);
}

.walls-container {
  display: grid;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.wall-input {
  display: grid;
  grid-template-columns: 1fr 2fr auto;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  background: white;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-sm);
  transition: all 0.2s ease;
}

.wall-input:hover {
  box-shadow: var(--shadow);
}

.wall-input label {
  font-weight: 500;
  color: var(--text-color);
}

.remove-wall {
  background: #fee2e2;
  color: var(--danger-color);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  transition: all 0.2s ease;
}

.remove-wall:hover {
  background: #fecaca;
  transform: scale(1.05);
}

.add-wall {
  background: #ecfdf5;
  color: var(--success-color);
  padding: 0.75rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.add-wall:hover {
  background: #d1fae5;
}

.add-wall svg {
  width: 1.25rem;
  height: 1.25rem;
}

.settings {
  display: grid;
  gap: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e5e7eb;
}

.setting-input {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1rem;
  align-items: center;
}

.setting-input label {
  font-weight: 500;
  color: var(--text-color);
}

.setting-checkbox {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  padding: 0.75rem;
  background: white;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-sm);
}

.setting-checkbox input[type="checkbox"] {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 4px;
  border: 2px solid #e5e7eb;
  cursor: pointer;
}

.setting-checkbox label {
  font-weight: 500;
  color: var(--text-color);
} 